import { request } from "@/utils/request"

export function convertUsernameToFbId(data) {
    data = { username: data }
    return request({
        url: "api/fb-account/get-uid-from-username",
        method: "post",
        data
    })
}
export function getCoronaInfo() {
    return request({
        url: "api/advertising/get-corona-info",
        method: "get"
    })
}
export function orderSeedingJob(data) {
    return request({
        url: "api/advertising/create",
        method: "post",
        data
    })
}
export function orderSeedingCorona(data) {
    return request({
        url: "api/advertising/create-corona",
        method: "post",
        data
    })
}
export function getListJobOrder(param = null) {
    return request({
        url: "api/advertising/list" + (param ? param : ""),
        method: "get"
    })
}

export function reCheckID(id, data) {
    return request({
        url: "api/advertising/recheck-object-exits/" + id,
        method: "post",
        data
    })
}
export function removeOrder(data) {
    return request({
        url: "api/advertising/remove",
        method: "post",
        data
    })
}
export function orderVipClone(data) {
    return request({
        url: "api/advertising/vip-like-clone/buy",
        method: "post",
        data
    })
}
export function deleteVipClone(data) {
    data = { id: data }
    return request({
        url: "api/advertising/vip-like-clone/remove",
        method: "post",
        data
    })
}
export function getLogVip(fbId = null, packageName, limit = 500) {
    return request({
        url:
            "api/advertising/vip-like-sl/list-log-vip?limit=" +
            limit +
            ("&package_name=" + packageName) +
            (fbId ? "&fb_id=" + fbId : ""),
        method: "get"
    })
}
export function getListVipClone() {
    return request({
        url: "api/advertising/vip-like-clone",
        method: "get"
    })
}
export function orderVipLike(data) {
    return request({
        url: "api/advertising/vip/buy",
        method: "post",
        data
    })
}
export function deleteVipLike(id) {
    let data = { id: id }
    return request({
        url: "api/advertising/vip/remove/" + id,
        method: "post",
        data
    })
}
export function getListVipLike() {
    return request({
        url: "api/advertising/vip",
        method: "get"
    })
}
export function orderVipLikeSL(data) {
    return request({
        url: "api/advertising/vip-like-sl/create",
        method: "post",
        data
    })
}
export function deleteVipLikeSL(id) {
    let data = { id: id }
    return request({
        url: "api/advertising/vip-like-sl/remove",
        method: "post",
        data
    })
}
export function getListVipLikeSL() {
    return request({
        url: "api/advertising/vip-like-sl/list",
        method: "get"
    })
}
// vip react
export function orderVipReaction(data) {
    return request({
        url: "api/advertising/vip-reaction/buy",
        method: "post",
        data
    })
}
export function deleteVipReaction(data) {
    return request({
        url: "api/advertising/vip-reaction/remove",
        method: "post",
        data
    })
}
export function getListVipReaction() {
    return request({
        url: "api/advertising/vip-reaction",
        method: "get"
    })
}

// // vip comment
export function orderVipComment(data) {
    return request({
        url: "api/advertising/vip-comment/buy",
        method: "post",
        data
    })
}
export function deleteVipComment(data) {
    return request({
        url: "api/advertising/vip-comment/remove",
        method: "post",
        data
    })
}
export function editVipComment(fbId, data) {
    return request({
        url: "api/advertising/vip-comment/update/" + fbId,
        method: "post",
        data
    })
}
export function getListVipComment() {
    return request({
        url: "api/advertising/vip-comment",
        method: "get"
    })
}
// mắt sv 1
export function orderBuffEyeV1(data) {
    return request({
        url: "api/kg-api/eyes/buff",
        method: "post",
        data
    })
}
export function getListBuffEyeV1() {
    return request({
        url: "api/kg-api/eyes/logs",
        method: "get"
    })
}
export function checkLikePage(data) {
    return request({
        url: "api/advertising/check/check-can-buff",
        method: "post",
        data
    })
}
// mắt sv 2
export function orderBuffEyeV2(data) {
    return request({
        url: "api/buff-eyes-v2/create",
        method: "post",
        data
    })
}
export function listBuffEyeV2(limit, id) {
    return request({
        url: "api/buff-view-v2?action=eyes&limit=" + limit + (id ? "&object_id=" + id : ""),
        method: "get"
    })
}
