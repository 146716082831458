import { render, staticRenderFns } from "./facebook-logs.vue?vue&type=template&id=5eb76a46&"
import script from "./facebook-logs.vue?vue&type=script&lang=js&"
export * from "./facebook-logs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports